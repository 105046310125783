const apiURL = process.env.REACT_APP_API_URL;
const rannLabURL = 'https://nseindiaapi.rannlabprojects.com';

const ApiConfig = {
    niftyTotal: apiURL + "/api/v1/fetchniftytotaldata",
    resetData: apiURL + `/api/v1/resetniftytotalsymbol/`,
    fetchNiftyIndices: apiURL + "/api/v1/fetchNiftyBankIndices",
    fetchNifty50Indices: apiURL + "/api/v1/fetchNifty50Indices",
    updateNiftyIndices: apiURL + "/api/v1/updateNiftyIndices",
    fetchNifty50PreOpenData: rannLabURL + "/api/nifty/fetchnifty50preopendata",
    fetchNiftyBankPreOpenData: rannLabURL + "/api/nifty/fetchniftybankpreopendata",
} 

export default ApiConfig