import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import * as XLSX from "xlsx";
import ApiConfig from "../Api/ApiConfig";
import Buttons from "./buttons";
import { Button } from "@mui/material";

const NiftyAll = () => {
  const [rowData, setRowData] = useState([]);
  const [Nifty50PreOpenData, setNifty50PreOpenData] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          Nifty50PreOpenData
            ? ApiConfig.fetchNifty50PreOpenData
            : ApiConfig.fetchNiftyBankPreOpenData
        );
        const data = response?.data?.data;
        for (const item of data) {
          if (item.updatedAt) {
            const utcDate = new Date(item.updatedAt);
            const istDate = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000);
            item.updatedAt = istDate
              .toISOString()
              .replace("T", " ")
              .substring(0, 19);
          }
        }
        const totaltotalSellAndSum4SellVstotalBuyAndSum4Buy = data.reduce(
          (sum, row) =>
            sum + row.calculatedData.totalSellAndSum4SellVstotalBuyAndSum4Buy,
          0
        );
        const totaltotalBuyAndSum4BuyVstotalSellAndSum4Sell = data.reduce(
          (sum, row) =>
            sum + row.calculatedData.totalBuyAndSum4BuyVstotalSellAndSum4Sell,
          0
        );
        const totalbuyValue = data.reduce((sum, row) => sum + row.buyValue, 0);
        const totalsellValue = data.reduce(
          (sum, row) => sum + row.sellValue,
          0
        );
        const totalbuyValueVsSellValue = data.reduce(
          (sum, row) => sum + row.buyValueVsSellValue,
          0
        );
        const totalsellValueVsBuyValue = data.reduce(
          (sum, row) => sum + row.sellValueVsBuyValue,
          0
        );
        const totalTotalSellVsTotalBuy = data.reduce(
          (sum, row) => sum + row.calculatedData.totalSellVsTotalBuy,
          0
        );
        const totalBuySubtractedValue = data.reduce(
          (sum, row) => sum + row.calculatedData.buySubtractedValue,
          0
        );
        const totalSubtractedValueAndSellValue = data.reduce(
          (sum, row) => sum + row.subtractedValueAndSellValue,
          0
        );
        const totalBSSBV = data.reduce((sum, row) => sum + row.BSSBV, 0);
        const totalSVV = data.reduce((sum, row) => sum + row.SVV, 0);
        const totalBSSV = data.reduce((sum, row) => sum + row.BSSV, 0);
        const totalSSVVMinusBSVV = data.reduce(
          (sum, row) => sum + row.SSVVMinusBSVV,
          0
        );
        const totalBSVVMinusSSVV = data.reduce(
          (sum, row) => sum + row.BSVVMinusSSVV,
          0
        );
        const totalSSVSVMinusBSVSV = data.reduce(
          (sum, row) => sum + row.SSVSVMinusBSVSV,
          0
        );
        const totalBSVBVMinusSSVSV = data.reduce(
          (sum, row) => sum + row.BSVBVMinusSSVSV,
          0
        );
        const totalsellValueVsBuyValueAndValueMinusBuyValueVsSellValueAndValue =
          data.reduce(
            (sum, row) =>
              sum +
              row.sellValueVsBuyValueAndValueMinusBuyValueVsSellValueAndValue,
            0
          );
        const totalbuyValueVsSellValueAndValueMinusBuyValueVsSellValueAndValue =
          data.reduce(
            (sum, row) =>
              sum +
              row.buyValueVsSellValueAndValueMinusSellValueVsBuyValueAndValue,
            0
          );

        // Add total calculations for remaining numeric fields
        const totalPChange = data.reduce(
          (sum, row) => sum + (parseFloat(row.pChange) || 0),
          0
        );
        const totalIep = data.reduce(
          (sum, row) => sum + (parseFloat(row.iep) || 0),
          0
        );
        const totalTurnoverVal = data.reduce(
          (sum, row) => sum + (parseFloat(row.totalTurnover) || 0),
          0
        );
        const totalSubtractedValue = data.reduce(
          (sum, row) => sum + (parseFloat(row.subtractedValue) || 0),
          0
        );
        const totalPreviousClose = data.reduce(
          (sum, row) => sum + (parseFloat(row.previousClose) || 0),
          0
        );
        const totalChange = data.reduce(
          (sum, row) => sum + (parseFloat(row.change) || 0),
          0
        );
        const totalLastPrice = data.reduce(
          (sum, row) => sum + (parseFloat(row.lastPrice) || 0),
          0
        );
        const totalFinalQuantity = data.reduce(
          (sum, row) => sum + (parseFloat(row.finalQuantity) || 0),
          0
        );
        const totalYearHigh = data.reduce(
          (sum, row) => sum + (parseFloat(row.yearHigh) || 0),
          0
        );
        const totalYearLow = data.reduce(
          (sum, row) => sum + (parseFloat(row.yearLow) || 0),
          0
        );
        const totalCalculated = data.reduce(
          (sum, row) => sum + (parseFloat(row.calculated) || 0),
          0
        );
        const totalTotalBuyQuantity = data.reduce(
          (sum, row) => sum + (parseFloat(row.totalBuyQuantity) || 0),
          0
        );
        const totalTotalSellQuantity = data.reduce(
          (sum, row) => sum + (parseFloat(row.totalSellQuantity) || 0),
          0
        );
        const totalSum4rowBuyQty = data.reduce(
          (sum, row) => sum + (parseFloat(row.sum4rowBuyQty) || 0),
          0
        );
        const totalSum4rowSellQty = data.reduce(
          (sum, row) => sum + (parseFloat(row.sum4rowSellQty) || 0),
          0
        );
        const totalAtoPrice = data.reduce(
          (sum, row) => sum + (parseFloat(row.atoPrice) || 0),
          0
        );
        const totalTotalBuyAnd4Buy = data.reduce(
          (sum, row) => sum + (row.calculatedData?.totalBuyAnd4Buy || 0),
          0
        );
        const totalTotalSellAnd4Sell = data.reduce(
          (sum, row) => sum + (row.calculatedData?.totalSellAnd4Sell || 0),
          0
        );
        const totalTotalBuyVsTotalSell = data.reduce(
          (sum, row) => sum + (row.calculatedData?.totalBuyVsTotalSell || 0),
          0
        );

        // Define new sums for buyValueVsSellValueAndValue and sellValueVsBuyValueAndValue
        const totalBuyValueVsSellValueAndValue = data.reduce(
          (sum, row) =>
            sum + (parseFloat(row.buyValueVsSellValueAndValue) || 0),
          0
        );
        const totalSellValueVsBuyValueAndValue = data.reduce(
          (sum, row) =>
            sum + (parseFloat(row.sellValueVsBuyValueAndValue) || 0),
          0
        );
        const totalsellSubractedValueAndTotalSellAndSum4SellVsTotalBuyAndSum4Buy =
          data.reduce(
            (sum, row) =>
              sum +
              (parseFloat(
                row.sellSubractedValueAndTotalSellAndSum4SellVsTotalBuyAndSum4Buy
              ) || 0),
            0
          );
        const totalbuySubractedValueAndTotalBuyAndSum4BuyVstotalSellAndSum4Sell =
          data.reduce(
            (sum, row) =>
              sum +
              (parseFloat(
                row.buySubractedValueAndTotalBuyAndSum4BuyVstotalSellAndSum4Sell
              ) || 0),
            0
          );
        
        const totalWeightage = data.reduce(
          (sum, row) => sum + (parseFloat(row.weightage.weightage) || 0),
          0
        );
        const totalBuyValueAndIndice = data.reduce(
          (sum, row) => sum + (parseFloat(row.weightage.buyValueAndIndice) || 0),
          0
        );
        const totalSellValueAndIndice = data.reduce(
          (sum, row) => sum + (parseFloat(row.weightage.sellValueAndIndice) || 0),
          0
        );

        // Add a totals row to the data
        data.push({
          symbol: "Total",
          calculatedData: {
            totalSellAndSum4SellVstotalBuyAndSum4Buy:
              totaltotalSellAndSum4SellVstotalBuyAndSum4Buy.toFixed(2),
            totalBuyAndSum4BuyVstotalSellAndSum4Sell:
              totaltotalBuyAndSum4BuyVstotalSellAndSum4Sell.toFixed(2),
          },
          buyValue: totalbuyValue.toFixed(2),
          sellValue: totalsellValue.toFixed(2),
          buyValueVsSellValue: totalbuyValueVsSellValue.toFixed(2),
          sellValueVsBuyValue: totalsellValueVsBuyValue.toFixed(2),
          subtractedValueAndSellValue:
            totalSubtractedValueAndSellValue.toFixed(2),
          BSSBV: totalBSSBV.toFixed(2),
          SVV: totalSVV.toFixed(2),
          BSSV: totalBSSV.toFixed(2),
          SSVVMinusBSVV: totalSSVVMinusBSVV.toFixed(2),
          BSVVMinusSSVV: totalBSVVMinusSSVV.toFixed(2),
          SSVSVMinusBSVSV: totalSSVSVMinusBSVSV.toFixed(2),
          BSVBVMinusSSVSV: totalBSVBVMinusSSVSV.toFixed(2),
          sellValueVsBuyValueAndValueMinusBuyValueVsSellValueAndValue:
            totalsellValueVsBuyValueAndValueMinusBuyValueVsSellValueAndValue.toFixed(
              2
            ),
          buyValueVsSellValueAndValueMinusSellValueVsBuyValueAndValue:
            totalbuyValueVsSellValueAndValueMinusBuyValueVsSellValueAndValue.toFixed(
              2
            ),
          buySubractedValueAndTotalBuyAndSum4BuyVstotalSellAndSum4Sell:
            totalbuySubractedValueAndTotalBuyAndSum4BuyVstotalSellAndSum4Sell.toFixed(
              2
            ),
          sellSubractedValueAndTotalSellAndSum4SellVsTotalBuyAndSum4Buy:
            totalsellSubractedValueAndTotalSellAndSum4SellVsTotalBuyAndSum4Buy.toFixed(
              2
            ),
          pChange: totalPChange.toFixed(2),
          iep: totalIep.toFixed(2),
          totalTurnover: totalTurnoverVal.toFixed(2),
          subtractedValue: totalSubtractedValue.toFixed(2),
          previousClose: totalPreviousClose.toFixed(2),
          change: totalChange.toFixed(2),
          lastPrice: totalLastPrice.toFixed(2),
          finalQuantity: totalFinalQuantity.toFixed(2),
          yearHigh: totalYearHigh.toFixed(2),
          yearLow: totalYearLow.toFixed(2),
          calculated: totalCalculated.toFixed(2),
          totalBuyQuantity: totalTotalBuyQuantity.toFixed(2),
          totalSellQuantity: totalTotalSellQuantity.toFixed(2),
          sum4rowBuyQty: totalSum4rowBuyQty.toFixed(2),
          sum4rowSellQty: totalSum4rowSellQty.toFixed(2),
          atoPrice: totalAtoPrice.toFixed(2),
          calculatedData: {
            totalSellVsTotalBuy: totalTotalSellVsTotalBuy.toFixed(2),
            buySubtractedValue: totalBuySubtractedValue.toFixed(2),
            totalSellAndSum4SellVstotalBuyAndSum4Buy:
              totaltotalSellAndSum4SellVstotalBuyAndSum4Buy.toFixed(2),
            totalBuyAndSum4BuyVstotalSellAndSum4Sell:
              totaltotalBuyAndSum4BuyVstotalSellAndSum4Sell.toFixed(2),
            totalBuyAnd4Buy: totalTotalBuyAnd4Buy.toFixed(2),
            totalSellAnd4Sell: totalTotalSellAnd4Sell.toFixed(2),
            totalBuyVsTotalSell: totalTotalBuyVsTotalSell.toFixed(2),
          },
          buyValueVsSellValueAndValue:
            totalBuyValueVsSellValueAndValue.toFixed(2),
          sellValueVsBuyValueAndValue:
            totalSellValueVsBuyValueAndValue.toFixed(2),
          weightage: {
            weightage: totalWeightage.toFixed(2),
            buyValueAndIndice: totalBuyValueAndIndice.toFixed(2),
            sellValueAndIndice: totalSellValueAndIndice.toFixed(2),
          },
          pinned: "bottom",
        });
        setRowData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [ Nifty50PreOpenData]);
  const numericComparator = (valueA, valueB) => {
    const numA = parseFloat(valueA) || 0;
    const numB = parseFloat(valueB) || 0;
    return numA - numB;
  };
  const columns = [
    {
      headerName: "Pre Open Market",
      children: [
        {
          field: "symbol",
          headerName: "Symbol",
          width: 130,
          comparator: numericComparator,
          cellDataType: "numeric",
          pinned: "left",
        },
        {
          field: "chart",
          headerName: "Trading View Chart",
          width: 120,
          comparator: numericComparator,
          cellDataType: "numeric",
          pinned: "left",
          cellRenderer: (params) => (
            <a
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              View Chart
            </a>
          ),
        },
        {
          field: "pChange",
          headerName: "%Chng",
          width: 100,
          comparator: numericComparator,
          cellDataType: "numeric",
          pinned: "left",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "iep",
          headerName: "IEP",
          width: 100,
          comparator: numericComparator,
          cellDataType: "numeric",
          pinned: "left",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "totalTurnover",
          headerName: "Value (₹ crores)",
          width: 100,
          comparator: numericComparator,
          cellDataType: "numeric",
          pinned: "left",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "subtractedValue",
          headerName: "Sell Subtracted Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          pinned: "left",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "calculatedData.buySubtractedValue",
          headerName: "Buy Subtracted Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          pinned: "left",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field:
            "sellSubractedValueAndTotalSellAndSum4SellVsTotalBuyAndSum4Buy",
          headerName:
            "Sell Subtracted Value* [ (Total sell + sum 4 sell ) / ( (Total Buy + sum 4 buy ) ]",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          pinned: "left",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "buySubractedValueAndTotalBuyAndSum4BuyVstotalSellAndSum4Sell",
          headerName:
            "Buy Subtracted Value* [ (Total Buy + sum 4 buy ) / ( (Total Sell + sum 4 sell ) ]",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          pinned: "left",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "previousClose",
          headerName: "Prev. Close",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "change",
          headerName: "Chng",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "lastPrice",
          headerName: "Final",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "finalQuantity",
          headerName: "Final Quantity ( lakhs )",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "yearHigh",
          headerName: "NM 52W H",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "yearLow",
          headerName: "NM 52W L",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
      ],
    },
    {
      headerName: "Calculation",
      children: [
        {
          field: "calculated",
          headerName: "(LTP-52W H)*100 /52W H   [{H-M}*100/M]",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
      ],
    },
    {
      headerName: "Pre Open Book",
      children: [
        {
          field: "totalBuyQuantity",
          headerName: "Total Buy Qty",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "totalSellQuantity",
          headerName: "Total Sell Qty",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },

        {
          field: "sum4rowBuyQty",
          headerName: "Sum (4 Rows) Buy Qty",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "sum4rowSellQty",
          headerName: "Sum (4 Rows) Sell Qty",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "atoPrice",
          headerName: "ATO Price",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        // {
        //   field: "atoBuyQty",
        //   headerName: "ATO Buy Qty",
        //   width: 150,
        //   comparator: numericComparator,
        //   cellDataType: "numeric",
        //   valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        // },
        // {
        //   field: "atoSellQty",
        //   headerName: "ATO Sell Qty",
        //   width: 150,
        //   comparator: numericComparator,
        //   cellDataType: "numeric",
        //   valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        // },
        // {
        //   field: "atoBuyVsSell",
        //   headerName: "ATO Buy Qty/ ATO Sell Qty",
        //   width: 150,
        //   comparator: numericComparator,
        //   cellDataType: "numeric",
        //   valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        // },
        // {
        //   field: "atoSellVsBuy",
        //   headerName: "ATO Sell Qty/ ATO Buy Qty",
        //   width: 150,
        //   comparator: numericComparator,
        //   cellDataType: "numeric",
        //   valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        // },
      ],
    },
    {
      headerName: "Calculation",
      children: [
        {
          field: "calculatedData.totalBuyAnd4Buy",
          headerName: "Total Buy + Sum 4 Buy",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "calculatedData.totalSellAnd4Sell",
          headerName: "Total Sell + Sum 4 Sell",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "calculatedData.totalBuyVsTotalSell",
          headerName: "Total Sell Qty / Total Buy Qty",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        // {
        //   field: "calculatedData.sum4SellVssum4Buy",
        //   headerName: "Sum Sell 4 Qty / Sum Buy 4 Qty",
        //   width: 150,
        //   comparator: numericComparator,
        //   cellDataType: "numeric",
        //   valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        // },
        // {
        //     field: 'additionValue',
        //     headerName: 'Added Value',
        //     width: 150, comparator: numericComparator, cellDataType: 'numeric',
        //     valueFormatter: (params) => (params.value || 0).toFixed(2),
        // },
        {
          field: "calculatedData.totalSellAndSum4SellVstotalBuyAndSum4Buy",
          headerName: "Total sell + sum 4 sell / Total Buy + Sum 4 buy",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "calculatedData.totalSellVsTotalBuy",
          headerName: "Total Buy Qty/ Total Sell Qty",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "calculatedData.totalBuyAndSum4BuyVstotalSellAndSum4Sell",
          headerName: "Total Buy + Sum 4 buy/ Total sell + sum 4 sell",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "SSVSVMinusBSVSV",
          headerName: "SSVSV - BSVBV",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) =>
            Number(params.value / 10000000 || 0).toFixed(2),
        },
        {
          field: "BSVBVMinusSSVSV",
          headerName: "BSVBV - SSVSV",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) =>
            Number(params.value / 10000000 || 0).toFixed(2),
        },
        {
          field: "subtractedValueAndSellValue",
          headerName: "SSVSV: Sell Subracted Value * Sell Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) =>
            Number(params.value / 10000000 || 0).toFixed(2),
        },
        {
          field: "BSSBV",
          headerName: "BSVBV: Buy Subtracted Value * Buy Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) =>
            Number(params.value / 10000000 || 0).toFixed(2),
        },
        {
          field: "SSVVMinusBSVV",
          headerName: "SSVV - BSVV",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "BSVVMinusSSVV",
          headerName: "BSVV - SSVV",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "SVV",
          headerName: "SSVV:Sell Subracted Value * Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "BSSV",
          headerName: "BSVV: Buy Subtracted Value * Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "sellValueVsBuyValueAndValue",
          headerName: "Sell Value/ Buy Value * Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "buyValueVsSellValueAndValue",
          headerName: "Buy Value/ Sell Value * Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "buyValue",
          headerName: "Buy Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) =>
            Number(params.value / 10000000 || 0).toFixed(2),
        },
        {
          field: "sellValue",
          headerName: "Sell Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) =>
            Number(params.value / 10000000 || 0).toFixed(2),
        },
        {
          field: "sellValueVsBuyValueAndValueMinusBuyValueVsSellValueAndValue",
          headerName:
            "[(Sell Value / Buy Value ) * Value ] - [ (Buy Value / Sell Value ) * Value]",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "buyValueVsSellValueAndValueMinusSellValueVsBuyValueAndValue",
          headerName:
            "[(Buy Value / Sell Value ) * Value ] - [ (Sell Value / Buy Value) * Value]",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "buyValueVsSellValue",
          headerName: "Buy Value/ Sell Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "sellValueVsBuyValue",
          headerName: "Sell Value/ Buy Value",
          width: 150,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },

        // {
        //     field: 'calculatedData.sCrossV',
        //     headerName: 'Value',
        //     width: 150, comparator: numericComparator, cellDataType: 'numeric',
        //     valueFormatter: (params) => (params.value || 0).toFixed(2),
        // },
      ],
    },
    {
      headerName:"Weightage",
      children:[
        {
          field: "weightage.weightage",
          headerName: "Weightage",
          width: 120,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        },
        {
          field: "weightage.buyValueAndIndice",
          headerName: "Buy Value * Weightage",
          width: 120,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value/10000000 || 0).toFixed(2),
        },
        {
          field: "weightage.sellValueAndIndice",
          headerName: "Sell Value * Weightage",
          width: 120,
          comparator: numericComparator,
          cellDataType: "numeric",
          valueFormatter: (params) => Number(params.value/10000000 || 0).toFixed(2),
        },
        // {
        //   field: "weightage.buyValueVsSellValueAndValueAndIndice",
        //   headerName: "Buy Value/ Sell Value * Value * Weightage",
        //   width: 120,
        //   comparator: numericComparator,
        //   cellDataType: "numeric",
        //   valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        // },
        // {
        //   field: "weightage.sellValueVsBuyValueAndValueAndIndice",
        //   headerName: "Sell Value/ Buy Value * Value * Weightage",
        //   width: 120,
        //   comparator: numericComparator,
        //   cellDataType: "numeric",
        //   valueFormatter: (params) => Number(params.value || 0).toFixed(2),
        // },
      ]
    },
    {
      headerName: "Time",
      children: [
        {
          field: "updatedAt",
          headerName: "Updated At",
          width: 180,
          comparator: numericComparator,
          cellDataType: "numeric",
        },
      ],
    },
  ];
  const handleDownloadExcel = () => {
    // Define the correct main headers (group headers) for each set of columns

    const repeat = (item, times) => Array(times).fill(item);

    const mainHeaders = [
      ...repeat("Pre Open Market", 11),
      // 'Calculation',
      ...repeat("Pre Open Book", 5),
      ...repeat("Calculation", 17),
      ...repeat("Time", 1),
    ];

    // Define the subheaders corresponding to each field
    const subHeaders = columns.flatMap((col) =>
      col.children.map((col) => col.headerName)
    );

    // Manually map data for each row
    const formatTwoDecimals = (value) => parseFloat(value || 0).toFixed(2);
    const dataRows = rowData.map((row) => [
      row.symbol,
      row.chart,
      formatTwoDecimals(row.pChange),
      formatTwoDecimals(row.iep),
      formatTwoDecimals(row.totalTurnover),
      formatTwoDecimals(row.subtractedValue), //
      formatTwoDecimals(row.calculatedData?.buySubtractedValue),
      formatTwoDecimals(
        row.sellSubractedValueAndTotalSellAndSum4SellVsTotalBuyAndSum4Buy
      ),
      formatTwoDecimals(
        row.buySubractedValueAndTotalBuyAndSum4BuyVstotalSellAndSum4Sell
      ),
      row.previousClose,
      row.change,
      row.lastPrice,
      row.finalQuantity,
      row.yearHigh,
      row.yearLow,
      formatTwoDecimals(row.calculated),
      row.totalBuyQuantity,
      row.totalSellQuantity,
      row.sum4rowBuyQty,
      row.sum4rowSellQty,
      row.atoPrice,
      // row.atoBuyQty,
      // row.atoSellQty,
      // row.atoBuyVsSell,
      // row.atoSellVsBuy,
      formatTwoDecimals(row.calculatedData?.totalBuyAnd4Buy),
      formatTwoDecimals(row.calculatedData?.totalSellAnd4Sell),
      formatTwoDecimals(row.calculatedData?.totalBuyVsTotalSell),
      // row.calculatedData?.sum4SellVssum4Buy,
      // row.calculatedData?.aditionValue,
      formatTwoDecimals(
        row.calculatedData?.totalSellAndSum4SellVstotalBuyAndSum4Buy
      ),
      formatTwoDecimals(row.calculatedData?.totalSellVsTotalBuy),
      formatTwoDecimals(
        row.calculatedData?.totalBuyAndSum4BuyVstotalSellAndSum4Sell
      ),
      formatTwoDecimals(row.SSVSVMinusBSVSV / 10000000),
      formatTwoDecimals(row.BSVBVMinusSSVSV / 10000000),
      formatTwoDecimals(row.subtractedValueAndSellValue / 10000000),
      formatTwoDecimals(row.BSSBV / 10000000),
      formatTwoDecimals(row.SSVVMinusBSVV),
      formatTwoDecimals(row.BSVVMinusSSVV),
      formatTwoDecimals(row.SVV),
      formatTwoDecimals(row.BSSV),
      formatTwoDecimals(row.sellValueVsBuyValueAndValue),
      formatTwoDecimals(row.buyValueVsSellValueAndValue),
      formatTwoDecimals(row.buyValue / 10000000),
      formatTwoDecimals(row.sellValue / 10000000),
      formatTwoDecimals(
        row.sellValueVsBuyValueAndValueMinusBuyValueVsSellValueAndValue
      ),
      formatTwoDecimals(
        row.buyValueVsSellValueAndValueMinusSellValueVsBuyValueAndValue
      ),
      formatTwoDecimals(row.buyValueVsSellValue),
      formatTwoDecimals(row.sellValueVsBuyValue),
      formatTwoDecimals(row.weightage?.weightage),
      formatTwoDecimals(row.weightage?.buyValueAndIndice),
      formatTwoDecimals(row.weightage?.sellValueAndIndice),
      row.updatedAt,
    ]);

    // Prepare the worksheet data with grouped headers and subheaders
    const worksheetData = [
      mainHeaders, // First row: main headers (groups)
      subHeaders, // Second row: subheaders (column names)
      ...dataRows, // Data rows
    ];

    // Create worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    worksheet["!cols"] = Array(worksheetData[1].length).fill({ wch: 20 });

    // Define merge ranges for group headers (merging horizontally across subheaders)
    const mergeRanges = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } }, // 'Pre Open Market' spanning 11 columns
      // { s: { r: 0, c: 10 }, e: { r: 0, c: 10 } }, // 'Pre Open Market' spanning 11 columns
      { s: { r: 0, c: 12 }, e: { r: 0, c: 16 } }, // 'Pre Open Book' spanning 7 columns
      { s: { r: 0, c: 17 }, e: { r: 0, c: 38 } }, // 'Calculation' spanning 7 columns
      // 'Trade Information' spanning 7 columns
      { s: { r: 0, c: 39 }, e: { r: 0, c: 39 } },
    ];

    // Add merge ranges to the worksheet
    if (!worksheet["!merges"]) worksheet["!merges"] = [];
    worksheet["!merges"].push(...mergeRanges);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "NiftyAllData");

    // Download the Excel file
    XLSX.writeFile(workbook, "NiftyAllData.xlsx");
  };

  return (
    <div className="ag-theme-alpine relative h-[60vh]   ">
      <h1 className="text-center text-4xl mb-8 font-bold tracking-widest">
        {Nifty50PreOpenData ? "Nifty 50" : "Nifty Bank"}
      </h1>
      <div className="absolute right-0 z-10 top-0 gap-2">
        <Buttons handleDownloadExcel={handleDownloadExcel} />
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{setNifty50PreOpenData(!Nifty50PreOpenData)}}
          sx={{ mb: 2, ml:2 }}
        >
          {Nifty50PreOpenData ? "Show Nifty Bank" : "Show Nifty 50"}
        </Button>
      </div>

      <AgGridReact
        rowData={rowData}
        columnDefs={columns}
        loadingOverlayComponentParams={{ loadingMessage: "Loading data..." }}
        defaultColDef={{
          resizable: true,
          sortable: true,
        }}
        alwaysShowHorizontalScroll={true}
        alwaysShowVerticalScroll={true}
        loading={loading ? true : false}
        pinnedTopRowData={columns.headerName} // For sticky headers
        pinnedBottomRowData={[]} // For sticky footers
        animateRows={true}
        onGridReady={(params) => {
          params.api.sizeColumnsToFit(); // Auto fit columns on grid ready
        }}
      />
    </div>
  );
};

export default NiftyAll;
