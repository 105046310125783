import React, { useState, useEffect } from "react";
import axios from "axios"; // Added import
import ApiConfig from "../Api/ApiConfig";

function NiftyBankIndices() {
  const [niftyBankIndices, setNiftyBankIndices] = useState([]); // Added state
  const [editedValues, setEditedValues] = useState({});



  const fetchIndices = async () => {
    try {
      const response = await axios.get(ApiConfig.fetchNiftyIndices);
      setNiftyBankIndices(response.data || []); // Set fetched data
    } catch (error) {
      console.error("Error fetching Nifty Bank Indices:", error);
    }
  };
  useEffect(() => {
    fetchIndices();
  }, []);

  const handleChange = (symbol, newValue) => {
    setEditedValues({ ...editedValues, [symbol]: newValue });
  };

  const handleSubmit = (symbol) => {
    const updatedValue = editedValues[symbol] ?? "";
    if(updatedValue === "" || Number(updatedValue) < 0 || Number(updatedValue) > 100) {
      window.alert("Please enter a valid value between 0 and 100");
      return;
    }
    console.log(`Submitted value for ${symbol}: ${updatedValue}`);
    axios
      .post(ApiConfig.updateNiftyIndices, {
        symbol,
        indice: updatedValue,
        market: "NIFTY BANK",
      })
      .then((response) => {
        console.log(response.data);
        fetchIndices();
        // setEditedValues((prev) => {
        //   const { [symbol]: _, ...rest } = prev;
        //   return rest;
        // });
      })
      .catch((error) => {
        console.error(`Error updating ${symbol}:`, error);
      });
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
      {niftyBankIndices?.map((item) => (
        <div key={item.symbol} className="border border-gray-300 rounded p-4">
          <div className="text-lg font-semibold mb-2">{item.symbol}</div>
          <input
            type="text"
            value={editedValues[item.symbol] ?? item.indice} // Changed 'item.value' to 'item.indice'
            onChange={(e) => handleChange(item.symbol, e.target.value)}
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          />
          <button
            onClick={() => handleSubmit(item.symbol)}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
          >
            Submit
          </button>
        </div>
      ))}
    </div>
  );
}

export default NiftyBankIndices;
